import React, { useMemo } from 'react'

const useLegendCalculate = (user) => {
  const calculation = useMemo(() => {
    if (!user || !user.events) return null
    const calcDuration = user.to - user.from
    const durationPercent = calcDuration * 0.01
    const summa = user.events.reduce(
      (acc, event) => {
        if (event.typeId === 'no') {
          acc.inactive += event.duration
        } else if (event.typeId === 30 || event.typeId === 17) {
          acc.active += event.duration
          acc.calling += event.duration
        } else if (event.typeId === '-3') {
        } else if (event.typeId === -1) {
          acc.active += event.duration
          acc.meting += event.duration
        } else if (event.typeId === -2) {
          acc.lunch += event.duration
          acc.inactive += event.duration
        } else {
          acc.active += event.duration
        }
        return acc
      },
      {
        meting: 0,
        lunch: 0,
        active: 0,
        calling: 0,
        inactive: 0,
      }
    )
    return {
      active: Math.round(summa.active / durationPercent),
      calling: summa.calling,
      meting: summa.meting,
      lunch: summa.lunch,
      inactive: Math.round(summa.inactive / durationPercent),
    }
  }, [user])
  return calculation
}

export default useLegendCalculate
