import React from 'react'

function ManagerIcon({ color = 'black', width = 50 }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '1em',
        paddingTop: '1em',
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width={width} version="1.1" viewBox="0 0 53.71 53.71">
        <g>
          <path
            fill={color}
            strokeWidth="0.265"
            d="M6.143 42.876l.075-10.782.687-1.455c1.504-3.185 4.549-5.179 8.332-5.454l2.093-.153 4.75 10.356c4.297 9.373 4.778 10.298 5.059 9.749.17-.334 2.251-4.834 4.624-10 2.372-5.166 4.438-9.557 4.592-9.757.214-.28.735-.326 2.258-.197 3.682.31 6.718 2.333 8.193 5.456l.687 1.455.074 10.782.075 10.782H6.068zm37.362.84c.504-.504.577-3.285.098-3.764-.174-.174-1.008-.317-1.852-.317-1.46 0-1.534-.032-1.534-.662 0-.558-.124-.661-.794-.661s-.794.103-.794.661c0 .632-.071.662-1.578.662-1.861 0-2.126.292-2.126 2.345 0 2.06.195 2.153 4.498 2.153 2.98 0 3.744-.078 4.082-.416zm-4.347-3.684c0-.655.12-1.191.265-1.191.145 0 .265.536.265 1.19 0 .655-.12 1.191-.265 1.191-.146 0-.265-.536-.265-1.19zm-14.003-.224l-1.69-3.611 1.621-3.507c.892-1.928 1.698-3.483 1.791-3.454.185.057 3.286 6.633 3.286 6.968 0 .114-.747 1.783-1.66 3.711l-1.658 3.505zm-.549-13.168c-2.416-.387-5.522-2.09-7.287-3.996-6.172-6.667-4.326-17.195 3.702-21.119 4.015-1.962 7.662-1.963 11.668-.002 8.03 3.931 9.874 14.454 3.702 21.12-1.844 1.993-4.873 3.605-7.551 4.02-1.944.302-2.21.301-4.234-.023z"
          ></path>
        </g>
      </svg>
    </div>
  )
}

export default ManagerIcon
