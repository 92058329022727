import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import TimeLineTicks from './TimeLineTicks'
import StandardDivBars from './StandardDivBars'
import { getPercents, getColor } from './tools'
import WorkTimeLabel from './WorkTimeLabel'
import Legend from './Legend'

/** @type {{[key:string]:React.CSSProperties}} */
const styles = {
  timelineWrapper: {
    marginBottom: '1em',
    padding: '0 1em',
  },
  main: {
    display: 'flex',
    position: 'relative',
    height: '1rem',
    backgroundColor: '#737e87',
    width: '100%',
    overflow: 'hidden',
  },
  emptyText: {
    textAlign: 'center',
    width: '100%',
    fontSize: '.7rem',
    height: '1rem',
    textTransform: 'uppercase',
  },
}

const durationToHuman = (seconds) => {
  const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60
  return (
    (numHours ? numHours + ' hours ' : '') +
    (numMinutes ? numMinutes + ' minutes ' : '') +
    numSeconds +
    ' seconds'
  )
}
const UniversalBar = (props) => {
  const getTitle = (event) => {
    if (event.typeId === 0) return `Активность ${durationToHuman(event.duration)}`
    if (event.typeId === 'no')
      return `${props.loc('noActivity')} ${durationToHuman(event.duration)}`
    if (event.typeId in props.typeNames) {
      return `${props.typeNames[event.typeId]} ${durationToHuman(event.duration)}`
    }
  }
  const data = useMemo(
    () => {
      let bars = []
      let timeline = { from: 0, to: 0 }
      let legend = { name: props.name, events: [] }
      if (props.timeLineType === 'fullDay') {
        if (props.events) {
          bars = props.events.map((event) => ({
            typeId: event.typeId,
            title: getTitle(event),
            color: getColor(event.typeId),
            ...getPercents(props.fromTimeUnix, props.toTimeUnix, event.startAt, event.duration),
          }))
        }
        legend = { ...legend, events: props.events, from: props.fromTimeUnix, to: props.actualTime }
        timeline = { from: props.fromTimeUnix, to: props.toTimeUnix }
      } else if (props.timeLineType === 'workButton' && props.workButtonDuration) {
        if (props.eventsWorkButton) {
          bars = props.eventsWorkButton.map((event) => ({
            typeId: event.typeId,
            title: getTitle(event),
            color: getColor(event.typeId),
            ...getPercents(
              props.workButtonDuration.start,
              props.workButtonDuration.stop,
              event.startAt,
              event.duration
            ),
          }))
        }
        legend = {
          ...legend,
          events: props.eventsWorkButton,
          from: props.workButtonDuration.start,
          to: props.workButtonDuration.stop,
        }
        timeline = { from: props.workButtonDuration.start, to: props.workButtonDuration.stop }
      } else {
        if (props.eventsInWorkDay) {
          bars = props.eventsInWorkDay.map((event) => ({
            typeId: event.typeId,
            title: getTitle(event),
            color: getColor(event.typeId),
            ...getPercents(
              props.workFromTimeUnix,
              props.workToTimeUnix,
              event.startAt,
              event.duration
            ),
          }))
          legend = {
            ...legend,
            events: props.eventsInWorkDay,
            from: props.workFromTimeUnix,
            to: props.actualTime,
          }
        }
        timeline = { from: props.workFromTimeUnix, to: props.workToTimeUnix }
      }
      return { bars, timeline, legend }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  )

  if (props.hideManager) return null
  if (!props.events)
    return (
      <div style={styles.timelineWrapper}>
        <Typography>{props.name}</Typography>
        <div style={styles.main}>
          <div style={styles.emptyText}>{props.loc('noActivity')}</div>
        </div>
      </div>
    )

  return (
    <div style={styles.timelineWrapper}>
      <Legend {...data.legend} loc={props.loc} />
      {props.workButtonDuration && (
        <WorkTimeLabel
          {...data.timeline}
          startPosition={props.workButtonDuration.start}
          stopPosition={props.workButtonDuration.stop}
        />
      )}
      <div style={styles.main}>
        <StandardDivBars bars={data.bars} />
      </div>
      <TimeLineTicks {...data.timeline} timezone={props.timezone} />
    </div>
  )
}

export default UniversalBar
