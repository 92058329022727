import api from '../../../api/oldApiRequests'

export const updateRemote = async (customerId, password, dispatch) => {
  try {
    const config = await api.getConfig(customerId, password)
    if (!config) throw Error('No config')
    const groups = await api.getGroups(customerId, password)
    if (!groups) throw Error('No group')
    const eventTypes = await api.getEvents(customerId, password)
    if (!eventTypes) throw Error('No event types')
    dispatch({
      type: 'setRemoteConfig',
      payload: { remoteConfig: config, groups, eventTypes },
    })
  } catch (error) {
    dispatch({ type: 'message', payload: { messageType: 'exception', text: 'message text' } })
  }
}
