import React from 'react'
import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
  const error = useRouteError()
  return (
    <div
      style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>ErrorPage From ContextView</h1>
        <p>Что-то пошло не так.</p>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    </div>
  )
}
