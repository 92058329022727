import React from 'react'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import styled from '@emotion/styled'
import moment from 'moment-timezone'
import useLegendCalculate from '../../../../tools/useLegendCalculate'

const Dot = styled.div(({ color }) => ({
  height: '.5rem',
  width: '.5rem',
  backgroundColor: color,
  margin: '0 .2rem',
}))

const durationToHuman = (seconds) => {
  const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60
  return (
    (numHours ? numHours + ' hours ' : '') +
    (numMinutes ? numMinutes + ' minutes ' : '') +
    numSeconds +
    ' seconds'
  )
}

const secToHumanReadable = (sec) => (sec ? moment.utc(sec * 1000).format('H:m') : 0)

const Legend = (props) => {
  const legend = useLegendCalculate(props)
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', minWidth: '200px', flexGrow: '1' }}
    >
      <Typography>{props.name}</Typography>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '.6rem' }}>
        <Tooltip title={props.loc('active')}>
          <Dot color="#00cd5d" />
        </Tooltip>
        &nbsp;{legend.active}% (
        <Tooltip title={`${props.loc('calling')} ${durationToHuman(legend.calling)}`}>
          <Dot color="#66c0ff" />
        </Tooltip>
        &nbsp;{secToHumanReadable(legend.calling)} {props.loc('hourPrefix')}
        <Tooltip title={`${props.loc('meeting')} ${durationToHuman(legend.meting)}`}>
          <Dot color="#9f83fc" />
        </Tooltip>
        &nbsp;{secToHumanReadable(legend.meting)} {props.loc('hourPrefix')}
        <Tooltip title={`${props.loc('lunch')} ${durationToHuman(legend.lunch)}`}>
          <Dot color="#ea997e" />
        </Tooltip>
        &nbsp;{secToHumanReadable(legend.lunch)}) {props.loc('hourPrefix')}
        <Tooltip title={props.loc('inactive')}>
          <Dot color="#e72b58" />
        </Tooltip>
        &nbsp;{legend.inactive > 100 ? 100 : legend.inactive} %
      </div>
    </div>
  )
}

export default React.memo(Legend)
