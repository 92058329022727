import React from 'react'
import { useTheme } from '@emotion/react'

/** @type {{[key:string]:React.CSSProperties}} */
const styles = {
  main: {
    display: 'flex',
    position: 'relative',
    height: '0.7rem',
    width: '100%',
    overflow: 'hidden',
  },
  lineBlock: {
    position: 'absolute',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: '.2rem .2rem 0 .2rem',
    borderRadius: '.3rem .3rem 0 0',
  },
}
const getPosition = (from, to, startPosition, stopPosition) => {
  const coefficient = 0.01 * (to - from)
  const left = (startPosition - from) / coefficient + '%'
  const width = (stopPosition - startPosition) / coefficient + '%'
  return { left, width }
}

const WorkTimeLabel = ({ from, to, startPosition, stopPosition }) => {
  const theme = useTheme()
  return (
    <div style={styles.main}>
      <div
        style={{
          ...styles.lineBlock,
          ...getPosition(from, to, startPosition, stopPosition),
          borderBlockColor: theme.palette.text.primary,
        }}
      ></div>
    </div>
  )
}

export default React.memo(WorkTimeLabel)
