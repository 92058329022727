import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import styled from '@emotion/styled'

const Bar = styled.div(({ color, typeId }) => {
  const getZIndex = (type) => {
    if (type === 'no') return 1
    if (type === -1 && type === -2) return 2
    return 5
  }
  return {
    position: 'absolute',
    height: 'inherit',
    backgroundColor: color,
    '&:hover': {
      opacity: 0.3,
    },
    zIndex: getZIndex(typeId),
  }
})

const StandardDivBars = (props) => {
  const { bars } = props
  return (
    <>
      {bars &&
        bars.map(({ typeId, left, width, title, color = 'red' }, key) => {
          return (
            <Tooltip title={title} key={key}>
              <Bar
                typeId={typeId}
                color={color}
                style={{
                  left,
                  width,
                }}
              ></Bar>
            </Tooltip>
          )
        })}
    </>
  )
}

export default React.memo(StandardDivBars)
