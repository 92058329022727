import React from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ManagerGroupDataView from './ManagerGroupDataView'

const ManagerList = ({ data }) => {
  if (!data) return null
  return (
    <Stack sx={{ marginTop: '5rem', padding: '0 .5em' }} spacing={2}>
      {data.map((group) => (
        <Paper
          key={`groupKey-${group.id}`}
          sx={{ padding: '1em' }}
          elevation={3}
          variant="elevation"
        >
          <Typography variant="h5">{group.name}</Typography>
          <Divider sx={{ marginBottom: '1em' }} />
          <ManagerGroupDataView group={group}></ManagerGroupDataView>
        </Paper>
      ))}
    </Stack>
  )
}

export default ManagerList
