import React from 'react'
import { CircularProgress } from '@mui/material'
import ThemeWrapper from '../components/ThemeWrapper'
import { useConfig } from '../store/StateProvider'

const LoadingPage = () => {
  const [config, setConfig, loc] = useConfig()
  return (
    <ThemeWrapper themeName={config.theme}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: (theme) => console.log('test'),
        }}
      >
        <CircularProgress sx={{ scale: 2 }} />
      </div>
    </ThemeWrapper>
  )
}

export default LoadingPage
