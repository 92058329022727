import React from 'react'

function QuadIcon(props) {
  const { color = '#00c1ff' } = props
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        margin: '10px',
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" {...props} version="1.1" viewBox="-5 -5 210 130">
        <g>
          <path
            fill={color}
            fillOpacity="1"
            d="M136.17 121.653c-12.307-1.517-23.906-7.486-32.679-16.817-7.143-7.597-13.84-17.083-23.053-32.658-6.654-11.247-7.936-13.255-14.495-22.698-4.721-6.798-10.116-11.305-17.078-14.268-6.61-2.814-15.061-3.57-21.494-1.925-2.523.645-6.523 2.52-8.715 4.084-.913.651-1.744 1.1-1.848.996-.103-.103-.148-.057-.1.102.05.159-.591.96-1.423 1.782-3.84 3.79-6.945 9.774-8.041 15.491-1.275 6.647-.331 16.089 2.26 22.613 2.516 6.332 6.182 12.104 10.38 16.342l2.08 2.1 11.668-10.405 4.904 5.498S12.58 115.472 12.46 115.513c-.12.042-5.058-5.174-5.058-5.174s9.66-8.997 9.66-9.098c0-.1-.985-1.039-2.187-2.085-6.88-5.984-12-14.92-14.146-24.685-.536-2.44-.591-3.402-.591-10.362 0-6.939.056-7.928.585-10.333C3.47 41.28 10.214 31.9 19.974 27.002c2.801-1.406 6.752-2.647 10.033-3.15 3.734-.573 12.2-.345 15.82.426 7.66 1.631 14.133 5.404 20.857 12.155 3.597 3.612 5.866 6.519 9.11 11.668 16.988 26.964 26.916 42.19 30.28 46.435 6.237 7.87 15.555 14.666 23.974 17.484 4.335 1.45 7.16 1.945 12.69 2.224 11.653.587 19.558-1.423 28.19-7.168 3.112-2.071 8.45-7.356 10.792-10.685 5.142-7.307 8.067-15.003 9.024-23.74.352-3.215.356-10.124.008-13.43-1.58-15.003-9.97-29.81-21.56-38.05l-2.339-1.664-11.216 10.324c-.164.151-4.648-5.36-4.731-5.713-.03-.125 26.042-23.924 26.042-23.924l4.792 5.79s-9.402 8.934-9.442 9.037c-.04.103.614.64 1.455 1.195 3.416 2.251 7.462 5.888 10.559 9.49 7.144 8.31 11.512 17.946 13.211 29.142.617 4.065.612 14.827-.009 19.58-1.71 13.086-8.146 26.061-17.098 34.469-6.545 6.147-14.845 10.397-23.933 12.252-2.548.52-3.834.6-10.701.66-4.293.037-8.618-.033-9.611-.156z"
          ></path>
        </g>
      </svg>
    </div>
  )
}

export default QuadIcon
