import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ContextView from './panels/ContextView'
import './main.scss'

const router = createBrowserRouter([
  {
    path: '*',
    element: <ContextView />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<RouterProvider router={router} />)
