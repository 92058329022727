import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useConfig } from '../store/StateProvider'
import ThemeWrapper from '../components/ThemeWrapper'
import {
  Grid,
  AppBar,
  Switch,
  Toolbar,
  FormGroup,
  FormControlLabel,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useNavigate } from 'react-router-dom'
import LoadingPage from './LoadingPage'

const EventsConfigPage = () => {
  const [config, setConfig, loc] = useConfig()
  const [changes, setChanges] = useState(config.disabledTypes)
  const [busy, setBusy] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setConfig({ type: 'updateCredentials', payload: { id } })
    }
  }, [setConfig, id])
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes])

  if (!config.configReady) return <LoadingPage />
  const closePage = () => {
    setConfig({ type: 'rawUpdate', payload: { disabledTypes: changes } })
    setBusy(true)
    navigate(-1)
  }
  const localType = (typeId) => !(changes && changes.find((type) => type === typeId))

  return (
    <ThemeWrapper themeName={config.theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={busy}
        onClick={console.log}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {loc('eventsSetting')}
          </Typography>
          <IconButton size="large" color="inherit" aria-label="menu" onClick={closePage}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} sx={{ padding: 8 }}>
        {config.eventTypes.map((eventType) => (
          <Grid item key={eventType.typeId} xs={12} sm={6} md={4} lg={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={localType(eventType.typeId)}
                    onChange={(event) =>
                      setChanges((state) => {
                        if (state.find((type) => type === eventType.typeId)) {
                          return state.filter((type) => type !== eventType.typeId)
                        } else {
                          return [...state, eventType.typeId]
                        }
                      })
                    }
                  />
                }
                label={eventType[`title${config.lang.toUpperCase()}`]}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </ThemeWrapper>
  )
}

export default EventsConfigPage
