// @ts-check
/**
 * @typedef {object} ConfigObj
 * @param {boolean} customEventsEnabled Включены ли кастомные события
 * @param {number} maxMinutesBetweenEventsCountAsWork Хз что такое
 * @param {boolean} showTotalInTime
 * @param {string} subdomain Субдомен
 * @param {string} timezone
 * @param {string} workingHours Рабочие часы в формате 09-21
 * @param {boolean} customWorkingTime Тоже хз что такое
 * @param {object} NOT_UNION_EVENT_TYPES_MAP Пока тоже не ясно что
 */

/**
 * Возвращает настройки панели
 * @param {string} accountId
 * @param {string} password
 * @return {Promise<object>}
 */
const getConfig = async (accountId, password) => {
  const dataJson = await fetch(
    'https://clients.amoapi.ru/products/products.managers_activity_panel/getSettings',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountId, password }),
    }
  )
  if (dataJson.ok) {
    return dataJson.json()
  } else {
    throw Error(`Connection error: ${dataJson.statusText}`)
  }
}

/**
 * Возвращает департаменты
 * @param {string} accountId
 * @param {string} password
 * @return {Promise<object>}
 */
const getGroups = async (accountId, password) => {
  const dataJson = await fetch(
    'https://clients.amoapi.ru/products/products.managers_activity_panel/getDepartmentsManagers',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountId, password }),
    }
  )
  if (dataJson.ok) {
    return dataJson.json()
  } else {
    throw Error(`Connection error: ${dataJson.statusText}`)
  }
}

/**
 * Возвращает типы событий
 * @param {string} accountId
 * @param {string} password
 * @return {Promise<object>}
 */
const getEvents = async (accountId, password) => {
  const dataJson = await fetch(
    'https://clients.amoapi.ru/products/products.managers_activity_panel/getEventTypes',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountId, password }),
    }
  )
  if (dataJson.ok) {
    return dataJson.json()
  } else {
    throw Error(`Connection error: ${dataJson.statusText}`)
  }
}

/**
 * Возвращает события
 * @param {string} accountId
 * @param {string} password
 * @param {string} eventTypes Запрашиваемые типы событий
 * @param {string} fromTimeUnix С какого времени
 * @param {string} toTimeUnix По какое
 * @param {any} users Пока тоже не ясно
 * @param {any} day Хз что такое
 * @return {Promise<object>}
 */
const getData = async (
  accountId,
  password,
  fromTimeUnix,
  toTimeUnix,
  eventTypes,
  day = null,
  users = null
) => {
  const dataJson = await fetch(
    'https://clients.amoapi.ru/products/products.managers_activity_panel/getData',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId,
        password,
        fromTimeUnix,
        toTimeUnix,
        eventTypes,
        day,
        users,
      }),
    }
  )
  if (dataJson.ok) {
    return dataJson.json()
  } else {
    throw Error(`Connection error: ${dataJson.statusText}`)
  }
}

const api = { getConfig, getGroups, getEvents, getData }
export default api
