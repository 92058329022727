import React, { useRef, useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import moment from 'moment-timezone'

/** @type {{[key:string]:React.CSSProperties}} */
const styles = {
  tickWrapper: {
    fontSize: '.9rem',
    position: 'relative',
    width: '100%',
    height: '1.5em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  oneTick: {
    position: 'absolute',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  oneTick_topRow: {
    display: 'flex',
    height: '.5em',
  },
  oneTick_bottomRow: {
    padding: '0 .3rem',
    display: 'flex',
    justifyContent: 'center',
    height: '1.4em',
    lineHeight: 1.4,
  },
  oneTick_dot: {
    width: '.2em',
    height: '.5em',
    display: 'flex',
  },
}

const getTicks = (from, to, locale, width) => {
  if (!width) return []
  const getPercents = (startPosition) => {
    const left = ((startPosition - from) / (to - from)) * 100
    return { left }
  }
  const getUnixTime = (position) => {
    return Math.floor(from + ((to - from) * position) / 100)
  }
  const result = []
  const pushDate = (time, tickFormat = 'H:mm') => {
    result.push({
      text: time.tz(locale).format(tickFormat),
      ...getPercents(time.unix()),
    })
  }
  const start = moment.unix(from)
  const end = moment.unix(to)
  pushDate(start)

  const startPosition = moment.unix(getUnixTime(width * 1.5))
  const endPosition = moment.unix(getUnixTime(100 - width * 1.5))
  const startOne = !(
    startPosition.minutes() ||
    startPosition.seconds() ||
    startPosition.milliseconds()
  )
    ? startPosition
    : startPosition.endOf('hour').add(1, 'second')
  const endOne = endPosition.startOf('hour')

  const duration = Math.round(moment.duration(endOne.diff(startOne)).asHours())
  const tickMaxCount = (100 - 3 * width) / width
  for (let hour = 0; hour <= duration; hour += 1) {
    const now = moment(startOne).add(hour, 'hours')
    if (tickMaxCount > duration) {
      pushDate(now)
    } else {
      if (hour % 3 === 0) pushDate(now)
    }
  }

  pushDate(end)
  return result
}

const getTicksOld = (from, to, locale, ticksCount) => {
  const getPercents = (startPosition) => {
    const left = ((startPosition - from) / (to - from)) * 100
    return { left }
  }
  const result = []
  const tickFormat = 'H:mm'
  const start = moment.unix(from)
  const end = moment.unix(to)
  const startOne = !(start.minutes() || start.seconds() || start.milliseconds())
    ? start
    : moment(start).endOf('hour').add(1, 'second')
  const endOne = moment(end).startOf('hour')
  if (startOne.unix() !== start.unix()) {
    result.push({
      text: start.tz(locale).format(tickFormat),
      ...getPercents(start.unix()),
    })
  }

  const duration = Math.round(moment.duration(endOne.diff(startOne)).asHours())
  for (let hour = 0; hour <= duration; hour++) {
    const now = moment(startOne).add(hour, 'hours')
    if (hour % 2 === 0)
      result.push({ text: now.tz(locale).format(tickFormat), ...getPercents(now.unix()) })
  }

  if (endOne.unix() !== end.unix()) {
    result.push({ text: end.tz(locale).format(tickFormat), ...getPercents(end.unix()) })
  }
  return result
}

const Tick = ({ text, position, left, translate, color = 'black' }) => (
  <div
    style={{
      ...styles.oneTick,
      left: left + '%',
      transform: `translateX(${translate})`,
    }}
  >
    <div style={{ ...styles.oneTick_topRow, justifyContent: position }}>
      <div style={{ ...styles.oneTick_dot, backgroundColor: color }}></div>
    </div>
    <div style={{ ...styles.oneTick_bottomRow, color }}>{text}</div>
  </div>
)

const CalculatePlaceholder = ({ changeWidth }) => {
  const wrapperRef = useRef(null)
  const calcTick = useRef(null)

  const resizeHandle = () => {
    const fullWidth = wrapperRef.current.clientWidth
    const tickWidth = calcTick.current.clientWidth
    changeWidth(Math.round(tickWidth / (fullWidth * 0.01)))
  }

  useEffect(() => {
    resizeHandle()
    window.addEventListener('resize', resizeHandle)
    return () => window.removeEventListener('resize', resizeHandle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={styles.tickWrapper} ref={wrapperRef}>
      <div style={styles.oneTick} ref={calcTick}>
        <div style={{ ...styles.oneTick_topRow }}>
          <div style={{ ...styles.oneTick_dot, backgroundColor: '#00000000' }}></div>
        </div>
        <div style={{ ...styles.oneTick_bottomRow, color: '#00000000' }}>55:55</div>
      </div>
    </div>
  )
}

const TimeLineTicks = ({ from, to, timezone = 'Moskow' }) => {
  const [ticksCount, setTicksCount] = useState(null)
  const ticks = getTicks(from, to, timezone, ticksCount)

  const theme = useTheme()

  // console.count('#TimeLineTicks render')
  const readyTicks = ticks.map((tick, idx, arr) => {
    if (idx === 0) {
      tick.position = 'start'
      tick.translate = '0%'
    } else if (idx === arr.length - 1) {
      tick.position = 'end'
      tick.translate = '-100%'
    } else {
      tick.position = 'center'
      tick.translate = '-50%'
    }
    return tick
  })

  return (
    <div style={styles.tickWrapper}>
      <CalculatePlaceholder changeWidth={(ticksWidth) => setTicksCount(ticksWidth)} />
      {readyTicks.map((tick, key) => (
        <Tick key={key} {...tick} color={theme.palette.text.primary} />
      ))}
    </div>
  )
}

export default React.memo(TimeLineTicks)
