import React, { useState, useRef } from 'react'
import 'moment/locale/ru'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Button, ClickAwayListener } from '@mui/material'
import { useConfig } from '../store/StateProvider'
import CalendarIcon from '@mui/icons-material/CalendarMonth'

const ClockButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '.7em',
  },
  textTransform: 'none',
}))

function DayPicker() {
  const [config, setConfig] = useConfig()
  const [open, setOpen] = useState(false)

  const onDayChange = (day) => setConfig({ type: 'setDate', payload: day })
  const day = config?.fromTimeUnix ? moment(config.fromTimeUnix * 1000) : moment.now()
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={config.lang || 'ru'}>
          <DatePicker
            label="Точка актуальности дата"
            onChange={(newDay) => {
              onDayChange(newDay)
              setOpen(false)
            }}
            open={open}
            closeOnSelect={true}
            value={day}
            componentsProps={{
              actionBar: {
                actions: ['cancel'],
              },
            }}
            onCancel={() => setOpen(false)}
            disableMaskedInput={true}
            disableFuture={true}
            inputFormat="LL"
            DialogProps={{ onClose: () => console.log('test') }}
            renderInput={({ inputRef, inputProps, InputProps }) => {
              return (
                <ClockButton
                  ref={inputRef}
                  color="inherit"
                  onClick={() => setOpen(!open)}
                  endIcon={<CalendarIcon />}
                >
                  {inputProps.value}
                </ClockButton>
              )
            }}
          ></DatePicker>
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  )
}

export default DayPicker
