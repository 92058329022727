import { colors } from '@mui/material'
import moment from 'moment-timezone'

export const getPercents = (from, to, startPosition, duration) => {
  const coefficient = 0.01 * (to - from)
  const left = (startPosition - from) / coefficient + '%'
  const w = duration / coefficient
  const width = w === 0 || duration < 3 ? '1px' : duration / coefficient + '%'
  return { left, width }
}

export const getColor = (typeId) => {
  const colorsObj = {
    0: '#00cd5d',
    '-3': colors.blue[400],
    '-2': '#ea997e',
    '-1': '#9f83fc',
    30: '#6bbefa',
    17: colors.cyan[400],
    no: '#b44660',
  }
  return String(typeId) in colorsObj ? colorsObj[typeId] : colors.red[800]
}

export const prepareAxis = (props) => {
  const result = { diapasons: [], layers: [], unwork: [] }
  for (const [layerType, layer] of Object.entries(props.events)) {
    const blocks = []
    const diapason = []
    for (const { typeId, startAt, duration } of layer.events) {
      const dura = moment.duration(duration, 'seconds').minutes()
      if (typeId === 'no') {
        result.unwork.push({
          typeId,
          name: `Не активен\n${dura} мин.`,
          ...getPercents(props.workFromTimeUnix, props.workToTimeUnix, startAt, duration),
          backgroundColor: getColor(typeId),
        })
      } else if (typeId === -3) {
        diapason.push({
          typeId,
          name: `${layer.name}\n${dura} мин.`,
          ...getPercents(props.workFromTimeUnix, props.workToTimeUnix, startAt, duration),
          backgroundColor: getColor(typeId),
        })
      } else {
        blocks.push({
          typeId,
          name: `${layer.name}\n${dura} мин.`,
          ...getPercents(props.workFromTimeUnix, props.workToTimeUnix, startAt, duration),
          backgroundColor: getColor(typeId),
        })
      }
    }
    result.layers.push(blocks)
    result.diapasons.push(diapason)
  }
  return result
  // eslint-disable-next-line react-hooks/exhaustive-deps
}
