import React from 'react'
import { AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { useConfig } from '../store/StateProvider'
import DayPicker from './DayPicker'
import Icon from './SSIcon'
import QuadIcon from './SSQuadIcon'

const Caption = styled(Typography)(({ theme }) => ({
  fontSize: '0.7em',
  letterSpacing: '.13em',
  textTransform: 'uppercase',
}))

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem',
  },
  textTransform: 'none',
}))

const LogoQuadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {},
}))

const Logo = styled(Icon)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem',
  },
  textTransform: 'none',
}))

function AppToolBar() {
  const [config, setConfig, loc] = useConfig()

  const showDrawer = () => setConfig({ type: 'rawUpdate', payload: { drawer: true } })
  return (
    <AppBar>
      <Toolbar color="primary">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={showDrawer}
        >
          <MenuIcon />
        </IconButton>
        <LogoQuadBox sx={{ flexGrow: 1 }}>
          <LogoBox>
            <Logo textColor={'#fff'} width={'9em'} />
            <Caption variant="h6" component="div">
              {loc('panelName')}
            </Caption>
          </LogoBox>
        </LogoQuadBox>
        <DayPicker />
      </Toolbar>
    </AppBar>
  )
}

export default AppToolBar
