import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import { getNoWorkTime, sliceDuration, getWorkDuration, joinDuration } from '../tools/calculates'

function useCalcData(config) {
  const parsedData = useMemo(() => {
    if (config.groups && config.data) {
      console.time('#ParseData')
      // Формируем даты
      // Рабочие часы с сервера
      const [start, end] = config.remoteConfig.workingHours.split('-')
      // Дата начала и конца выборки событий и время последнего обновления
      const { fromTimeUnix, toTimeUnix, lastUpdateTime } = config
      // Начало и конец рабочего дня по
      const workFromTimeUnix = moment
        .unix(fromTimeUnix)
        .add(Number(start) || 8, 'hours')
        .unix()
      const workToTimeUnix = moment
        .unix(fromTimeUnix)
        .add(Number(end) || 20, 'hours')
        .unix()
      // Точка актуальности либо текущий момент
      // В случае просмотра дат за прошлый период конец периода
      const actualTime = lastUpdateTime > toTimeUnix ? toTimeUnix : lastUpdateTime

      // Имена событий по типам событий
      const typeNames = config.eventTypes.reduce((acc, cur) => {
        acc[String(cur.typeId)] = cur.titleRU
        return acc
      }, {})

      // Сборка объекта для отрисовки
      // Пробегаемся по группам
      const result = config.groups.map((group) => {
        // Добавляем события к менеджерам
        const users = group.users.map((user) => {
          // Все события с начала и до конца выборки
          const eventsRaw = config.data[user.id] || null

          // Все события без событий кнопки работа (-3)
          const eventsWithoutWork = eventsRaw && eventsRaw.filter((event) => event.typeId !== -3)

          // Удаляем пустые звонки
          const eventsWithoutEmptyCalls =
            eventsWithoutWork && eventsWithoutWork.filter((event) => event.duration)

          // Сливаем события обеда и встречи если между ними меньше минуты
          const eventsJoin =
            eventsWithoutEmptyCalls && joinDuration(eventsWithoutEmptyCalls, [-1, -2])

          // Добавляем события простоя за весь период
          const events = eventsJoin && getNoWorkTime(fromTimeUnix, actualTime, eventsJoin, true)

          // Далее идут все варианты. Дублируются чтобы не размазывать логику по компонентам
          // События за рабочий день
          const eventsInWorkDay = events && sliceDuration(events, workFromTimeUnix, workToTimeUnix)

          // Промежуток нажатия на кнопку "работа"
          // события обрезаются потому что из апи приходит лишнее 20-30 секунд
          const workButtonDuration =
            eventsRaw && getWorkDuration(sliceDuration(eventsRaw, fromTimeUnix, actualTime))
          // События в промежутке нажатой кнопки
          const eventsWorkButton =
            workButtonDuration &&
            events &&
            sliceDuration(events, workButtonDuration.start, workButtonDuration.stop)

          // Зона из настроек
          const timezone = config.remoteConfig.timezone || 'Moscow'

          // Настройки менеджеров локальные
          const localUserConf =
            `${user.id}-${user.groupId}` in config.managersLocal
              ? config.managersLocal[`${user.id}-${user.groupId}`]
              : null

          const hideManager = localUserConf?.hideManager || false
          // Тип таймлайна
          const defaultTimeLineType =
            workButtonDuration && !localUserConf?.timelineType ? 'workButton' : 'standard'
          const timeLineType = localUserConf?.timelineType || defaultTimeLineType

          return {
            ...user,
            workButtonDuration,
            eventsWorkButton,
            eventsInWorkDay,
            events,
            fromTimeUnix,
            toTimeUnix,
            workFromTimeUnix,
            workToTimeUnix,
            actualTime,
            timeLineType,
            timezone,
            hideManager,
          }
        })
        return {
          ...group,
          users,
          typeNames,
        }
      })
      console.timeEnd('#ParseData')
      return result
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.data, config.groups])

  return parsedData
}

export default useCalcData
