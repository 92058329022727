import React, { useMemo } from 'react'
// import moment from 'moment-timezone'

const calcFullSum = (user, { type, duration = 0 }) => {
  if (!(type in user)) throw new ReferenceError(`Has no eventsType:${type}`)
  if (!duration) throw new ReferenceError(`Has no duration:${type}`)
  const summa = user[type].reduce(
    (acc, event) => {
      if (event.typeId === 'no') {
        acc.inactive.d += event.duration
        acc.inactive.c += 1
      } else if (event.typeId === 30 || event.typeId === 17) {
        acc.active.d += event.duration
        acc.calling.d += event.duration
        acc.calling.c += 1
      } else if (event.typeId === '-3') {
      } else if (event.typeId === -1) {
        acc.active.d += event.duration
        acc.meting.d += event.duration
        acc.meting.c += 1
      } else if (event.typeId === -2) {
        acc.inactive.d += event.duration
        acc.lunch.d += event.duration
        acc.lunch.c += 1
      } else {
        acc.active.d += event.duration
        acc.active.c += 1
      }
      return acc
    },
    {
      meting: { d: 0, c: 0 },
      lunch: { d: 0, c: 0 },
      active: { d: 0, c: 0 },
      calling: { d: 0, c: 0 },
      inactive: { d: 0, c: 0 },
    }
  )

  const durationPercent = duration * 0.01

  // Появляются непонятных 30 секунд у менеджеров с кнопкой но без действий
  if (summa.inactive.d > duration) summa.inactive.d = duration

  summa.active.c = Math.round(summa.active.d / durationPercent)
  summa.inactive.c = Math.round(summa.inactive.d / durationPercent)
  return { ...summa, duration }
}
const calcDuration = (user, calcType) => {
  const variants = {
    workDay: { type: 'events', duration: user.actualTime - user.fromTimeUnix || 0 },
    workTime: {
      type: 'eventsInWorkDay',
      duration:
        (user.actualTime < user.workToTimeUnix ? user.actualTime : user.workToTimeUnix) -
          user.workFromTimeUnix || 0,
    },
    workButton: {
      type: 'eventsWorkButton',
      duration: user.workButtonDuration
        ? user.workButtonDuration.stop - user.workButtonDuration.start
        : 0,
    },
  }
  if (!(calcType in variants)) throw new ReferenceError(`Has no timeType:${calcType}`)
  return calcFullSum(user, variants[calcType])
}

const useStatCalculate = (user, type = 'workTime') => {
  const calculation = useMemo(() => {
    if (!user || !user.events) return {}
    try {
      return calcDuration(user, type)
    } catch (err) {
      console.warn(err.message)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return calculation
}

export default useStatCalculate
