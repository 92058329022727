/**
 * @typedef eventJson
 * @type {Object}
 * @property {number|string} [typeId] Тип события
 * @property {number} startAt Начало события
 * @property {number} duration Длительность
 */

/**
 * Возвращает массив промежутков между событиями
 * @param {number} from начало промежутка в секундах
 * @param {number} to конец промежутка в секундах
 * @param {eventJson[]} events массив событий
 * @param {boolean} [add=false] добавлять в массив сами события
 * @return {eventJson[]}
 */
export function getNoWorkTime(from, to, events, add) {
  /** @type {eventJson[]} */
  const result = []
  const noWorkKey = 'no'
  for (const event of events) {
    if (event.startAt > from) {
      result.push({ typeId: noWorkKey, startAt: from, duration: event.startAt - from })
    }
    if (add) result.push(event)
    from = event.startAt + event.duration
    // Обработка событий
  }
  if (from < to) {
    result.push({ typeId: noWorkKey, startAt: from, duration: to - from })
  }
  return result
}

/**
 * Вырезать промежуток из событий
 * @param {eventJson[]} events массив событий
 * @param {number} from начало промежутка в секундах
 * @param {number} to конец промежутка в секундах
 * @return {eventJson[]}
 */
export function sliceDuration(events, from, to) {
  const result = []
  for (const event of events) {
    const stopAt = event.startAt + event.duration
    if (stopAt <= from || event.startAt >= to) continue
    if (event.startAt < from && stopAt > from) {
      result.push({ ...event, startAt: from, duration: stopAt - from })
    } else if (stopAt > to && event.startAt < to) {
      result.push({ ...event, duration: to - event.startAt })
      break
    } else {
      result.push(event)
    }
  }
  return result
}

/**
 * Возвращает промежуток между событиями со статусом -3
 * @param {eventJson[]} eventsFiltered массив событий
 * @param {number} status массив событий
 * @return {({start:number,stop:number}|null)}
 */
export function getWorkDuration(events, status = -3) {
  if (!Array.isArray(events)) return null
  let start
  let stop
  const eventsFiltered = events.filter((event) => event.typeId === status)
  if (eventsFiltered.length >= 2) {
    start = eventsFiltered[0].startAt
    stop =
      eventsFiltered[eventsFiltered.length - 1].startAt +
      eventsFiltered[eventsFiltered.length - 1].duration
  } else if (eventsFiltered.length === 1) {
    start = eventsFiltered[0].startAt
    stop = eventsFiltered[0].startAt + eventsFiltered[0].duration
  } else {
    return null
  }
  return { start, stop }
}

/**
 * Объединяет события в массиве короче duration
 * @param {eventJson[]} events События
 * @param {any[]} [events=[]] types Типы событий
 * @param {number} [duration=60] duration минимальная продолжительность между событиями
 * @returns {eventJson[]}
 */
export function joinDuration(events = [], types = [-1, -2], duration = 65) {
  if (events.length < 2) return events
  const result = []
  let currentEvent = null
  for (let event of events) {
    if (!currentEvent) {
      currentEvent = { ...event }
    } else if (
      types.find((typeId) => event.typeId === typeId) &&
      currentEvent.typeId === event.typeId
    ) {
      const splitLength = event.startAt - (currentEvent.startAt + currentEvent.duration)
      const endPosition = event.startAt + event.duration
      if (splitLength < duration) {
        currentEvent.duration = endPosition - currentEvent.startAt
      } else {
        result.push(currentEvent)
        currentEvent = { ...event }
      }
    } else {
      result.push(currentEvent)
      currentEvent = { ...event }
    }
  }
  result.push(currentEvent)
  return result
}
