import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import ManagerTableRow from './ManagerTableRow'

import { useConfig } from '../../store/StateProvider'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const ColumnContent = ({ children }) => {
  if (typeof children !== 'string') return <div></div>
  const [top, bottom] = children.split('/')
  return (
    <div>
      {top && <div>{top}</div>}
      {bottom && <div>{bottom}</div>}
    </div>
  )
}

const ManagerGroupDataView = ({ group }) => {
  const [config, configDispatch, loc] = useConfig()
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              <ColumnContent>Менеджер/יועצת</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Нажата кнопка работа/העבודה כפתור נלחץ</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Отжата кнопка работа/זמן עבודה</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Рабочее время/זמן עבודה</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Активное время/זמן פעילות</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Не активное время/זמן לא פעיל</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Звонки/שיחות</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Встречи/פגישה</ColumnContent>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ColumnContent>Обед/הפסקה</ColumnContent>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {group.users.map((manager) => (
            <ManagerTableRow data={manager} key={manager.name} config={config} loc={loc} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ManagerGroupDataView
