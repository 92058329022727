import React, { useState } from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  FormControl,
  Switch,
  Select,
  InputLabel,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useConfig } from '../store/StateProvider'
import ConfigIcon from '@mui/icons-material/Settings'
import QuadLogo from '../components/SSQuadIcon'
import Logo from '../components/SSIcon'
import { useTheme } from '@emotion/react'

function AppDrawer() {
  const [config, setConfig, loc] = useConfig()
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const location = useLocation()
  const theme = useTheme()

  if (!config.configReady) return null
  const toggleTheme = () =>
    setConfig({
      type: 'rawUpdate',
      payload: { theme: config.theme === 'light' ? 'dark' : 'light' },
    })
  const closeDrawer = () => {
    setConfig({ type: 'rawUpdate', payload: { drawer: false } })
  }
  const setLang = (ev) => {
    setConfig({ type: 'rawUpdate', payload: { lang: ev.target.value || 'ru' } })
  }

  return (
    <Drawer anchor="left" open={config.drawer} onClose={closeDrawer}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={busy}
        onClick={console.log}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <List
        subheader={
          <>
            <div style={{ display: 'flex' }}>
              <QuadLogo width="4em" />
              <Logo width="10em" textColor={theme.palette.primary.main} />
            </div>
            <ListSubheader>{loc('viewSettings')}</ListSubheader>
          </>
        }
      >
        <ListItem>
          <ListItemText
            primary={loc('theme')}
            secondary={config.theme === 'light' ? loc('light') : loc('dark')}
          />
          <Switch edge="end" onChange={toggleTheme} checked={config.theme !== 'light'} />
        </ListItem>
        <ListItem>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">{loc('name')}</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={config.lang}
              onChange={setLang}
              label={loc('name')}
            >
              <MenuItem value={'ru'}>Русский</MenuItem>
              <MenuItem value={'en'}>English</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              setBusy(true)
              navigate(`${location.pathname}/events`)
              closeDrawer()
            }}
          >
            <ListItemIcon>
              <ConfigIcon />
            </ListItemIcon>
            <ListItemText primary={loc('eventsSetting')} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              setBusy(true)
              navigate(`${location.pathname}/managers`)
              closeDrawer()
            }}
          >
            <ListItemIcon>
              <ConfigIcon />
            </ListItemIcon>
            <ListItemText primary={loc('groupSetting')} />
          </ListItemButton>
        </ListItem>
      </List>
      <List subheader={<ListSubheader>{loc('settings')}</ListSubheader>}>
        <ListItem>
          <ListItemText secondary={config.remoteConfig.workingHours} primary={loc('workTime')} />
        </ListItem>
        <ListItem>
          <ListItemText secondary={config.remoteConfig.timezone} primary={loc('timezone')} />
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={config.remoteConfig.customEventsEnabled ? loc('yes') : loc('no')}
            primary={loc('customEvents')}
          />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default AppDrawer
