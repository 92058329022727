import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useConfig } from '../store/StateProvider'
import AppToolBar from '../components/AppToolBar'
import ActivityView from '../components/ActivityView'
import LoadingPage from '../pages/LoadingPage'
import AppDrawer from '../components/AppDrawer'
import ThemeWrapper from '../components/ThemeWrapper'
import useCalcData from '../tools/useCalcData'

const MainView = () => {
  const [config, configDispatch, loc] = useConfig()
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      configDispatch({ type: 'updateCredentials', payload: { id } })
    }
  }, [configDispatch, id])

  const parsedData = useCalcData(config)

  if (!config.configReady) return <LoadingPage />
  return (
    <ThemeWrapper themeName={config.theme}>
      <AppToolBar />
      <ActivityView data={parsedData} loc={loc} />
      <AppDrawer />
    </ThemeWrapper>
  )
}

export default MainView
