import React from 'react'
import { deepmerge } from '@mui/utils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import GlobalStyles from '@mui/material/GlobalStyles'
import { useConfig } from '../store/StateProvider'

const simpleSalesPrimaryColor = '#062a45'

const themes = {
  default: {
    palette: {
      mode: 'light',
      primary: { main: simpleSalesPrimaryColor },
    },
  },
  light: {
    palette: {
      mode: 'light',
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  },
}
const theme = (themeName) => createTheme(deepmerge(themes.default, themes[themeName]))

export default function ThemeWrapper({ themeName = 'light', children }) {
  return (
    <ThemeProvider theme={theme(themeName)}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '::-webkit-scrollbar': {},
          '::-webkit-scrollbar-thumb': {
            backgroundColor: simpleSalesPrimaryColor,
          },
        }}
      />
      {children}
    </ThemeProvider>
  )
}
