import React, { memo } from 'react'
import useStatCalculate from '../../tools/useStatCalculate'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import moment from 'moment-timezone'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1em',
  },
}))

const timeString = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours}ч, ${minutes}м, ${seconds} c`
}

const ManagerTableRow = ({ data, config }) => {
  const { duration, active, inactive, calling, meting, lunch } = useStatCalculate(
    data,
    data.workButtonDuration ? 'workButton' : 'workTime'
  )
  const actionCell = (cellem, prefix = '') => {
    if (!cellem || !cellem.d) return '-'
    const { c, d } = cellem
    return `${timeString(d)} (${c}${prefix})`
  }

  const getNormalizeWorkButtonTime = () => {
    const result = { workButtonPressed: '-', workButtonUnPressed: '-' }
    if (data.workButtonDuration) {
      if (config.remoteConfig?.timezone) {
        result.workButtonPressed = moment
          .unix(data.workButtonDuration.start)
          .tz(config.remoteConfig.timezone)
          .format('D MMM HH:mm')
        result.workButtonUnPressed = moment
          .unix(data.workButtonDuration.stop)
          .tz(config.remoteConfig.timezone)
          .format('D MMM HH:mm')
      } else {
        result.workButtonPressed = moment.unix(data.workButtonDuration.start).format('D MMM HH:mm')
        result.workButtonUnPressed = moment.unix(data.workButtonDuration.stop).format('D MMM HH:mm')
      }

      if (
        data.workButtonDuration.start >= config.fromTimeUnix &&
        data.workButtonDuration.start <= config.fromTimeUnix + 10
      ) {
        result.workButtonPressed = '-'
      }
      if (data.workButtonDuration.stop === config.toTimeUnix) {
        result.workButtonUnPressed = '-'
      }
    }
    return result
  }
  const { workButtonPressed, workButtonUnPressed } = getNormalizeWorkButtonTime()

  const workingTime = duration ? timeString(duration) : '-'
  const activeTime = actionCell(active, '%')
  const noActiveTime = actionCell(inactive, '%')
  const calls = actionCell(calling)
  const meetings = actionCell(meting)
  const launch = actionCell(lunch)

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="right">
        {data.name}
      </StyledTableCell>
      <StyledTableCell align="right">{workButtonPressed}</StyledTableCell>
      <StyledTableCell align="right">{workButtonUnPressed}</StyledTableCell>
      <StyledTableCell align="right">{workingTime}</StyledTableCell>
      <StyledTableCell align="right">{activeTime}</StyledTableCell>
      <StyledTableCell align="right">{noActiveTime}</StyledTableCell>
      <StyledTableCell align="right">{calls}</StyledTableCell>
      <StyledTableCell align="right">{meetings}</StyledTableCell>
      <StyledTableCell align="right">{launch}</StyledTableCell>
    </StyledTableRow>
  )
}

export default ManagerTableRow
