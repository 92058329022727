import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useConfig } from '../store/StateProvider'
import ThemeWrapper from '../components/ThemeWrapper'
import {
  CircularProgress,
  Backdrop,
  Grid,
  AppBar,
  Switch,
  Toolbar,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  FormControlLabel,
  FormControl,
  Box,
  MenuItem,
  Select,
  CardActions,
  styled,
} from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useNavigate } from 'react-router-dom'
import LoadingPage from './LoadingPage'
import { display, Stack } from '@mui/system'
import { useState } from 'react'
import Manager from '../components/ManagerIco'

const PicManager = styled(Manager)(({ theme }) => ({}))

const GroupsConfigPage = () => {
  const [config, configDispatch, loc] = useConfig()
  const { id } = useParams()
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    if (id) {
      configDispatch({ type: 'updateCredentials', payload: { id } })
    }
  }, [configDispatch, id])
  const closePage = () => {
    setBusy(true)
    navigate(-1)
  }
  const setType = (user, flag) =>
    configDispatch({ type: 'setTimelineType', payload: { user, flag } })

  const setShow = (user, flag) =>
    configDispatch({ type: 'setHideManager', payload: { user, flag } })

  if (!config.configReady) return <LoadingPage />

  return (
    <ThemeWrapper themeName={config.theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={busy}
        onClick={console.log}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {loc('groupSetting')}
          </Typography>
          <IconButton size="large" color="inherit" aria-label="menu" onClick={closePage}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Stack spacing={4} sx={{ marginTop: 10, paddingX: 2 }}>
        {config.groups.map((group) => (
          <Stack container key={group.id}>
            <Typography variant="h5">{group.name}</Typography>
            <Grid container>
              {group.users.map((user) => {
                const savedConfig = config.managersLocal[`${user.id}-${user.groupId}`]
                const { timelineType, hideManager } = savedConfig || {}
                return (
                  <Grid item key={user.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Box sx={{ padding: '.5em' }}>
                      <Card sx={{ minWidth: 240, position: 'relative' }}>
                        <PicManager color={'#fff'} />
                        <CardHeader title={user.name} subheader={user.id} />
                        <CardContent>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={hideManager}
                                onChange={(event) => setShow(user, !hideManager)}
                              />
                            }
                            label={loc('hideManager')}
                          />
                          <CardActions>
                            <FormControl style={{ width: '100%' }}>
                              <InputLabel id="demo-simple-select-label">
                                {loc('lineType')}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={timelineType}
                                label={loc('lineType')}
                                onChange={(event) => setType(user, event.target.value)}
                              >
                                <MenuItem value={'standard'}>{loc('lineTypeStandard')}</MenuItem>
                                <MenuItem value={'workButton'}>
                                  {loc('lineTypeWorkButton')}
                                </MenuItem>
                                <MenuItem value={'fullDay'}>{loc('lineTypeFullDay')}</MenuItem>
                              </Select>
                            </FormControl>
                          </CardActions>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Stack>
        ))}
      </Stack>
    </ThemeWrapper>
  )
}

export default GroupsConfigPage
