import React from 'react'
import { Box, Stack, Typography, Paper, Divider } from '@mui/material'
import UniversalBar from './timelines/UniversalBar'

function ActivityView2({ data, loc }) {
  if (!data) return null
  console.count('#ActivityView Render')
  return (
    <Stack sx={{ marginTop: '5rem', padding: '0 .5em' }} spacing={2}>
      {data.map((group) => (
        <Paper
          key={`groupKey-${group.id}`}
          sx={{ padding: '1em' }}
          elevation={3}
          variant="elevation"
        >
          <Typography variant="h5">{group.name}</Typography>
          <Divider sx={{ marginBottom: '1em' }} />
          {group.users.map((user) => {
            return (
              <Box key={`userKey-${user.id}`}>
                <UniversalBar {...user} loc={loc} typeNames={group.typeNames} />
              </Box>
            )
          })}
        </Paper>
      ))}
    </Stack>
  )
}

export default React.memo(ActivityView2)
