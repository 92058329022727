import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useConfig } from '../store/StateProvider'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ThemeWrapper from '../components/ThemeWrapper'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField, Typography, Container, Switch, Button } from '@mui/material'
import moment from 'moment'

const DebugView = () => {
  const [config, configDispatch] = useConfig()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      configDispatch({ type: 'updateCredentials', payload: { id } })
    }
  }, [configDispatch, id])

  if (!config.configReady)
    return (
      <Container>
        <Typography variant="h1">Loading</Typography>
      </Container>
    )

  return (
    <ThemeWrapper themeName={config.theme}>
      <Switch
        checked={config.theme === 'dark'}
        onChange={(event) => {
          configDispatch({
            type: 'rawUpdate',
            payload: { theme: config.theme === 'dark' ? 'light' : 'dark' },
          })
        }}
      />
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru'}>
            <DatePicker
              label="Точка актуальности дата"
              color="primary"
              onChange={(newValue) => {
                configDispatch({ type: 'setDate', payload: newValue })
              }}
              value={moment.unix(config.fromTimeUnix)}
              renderInput={(props) => {
                return <TextField {...props} />
              }}
            ></DatePicker>
          </LocalizationProvider>
          <Button color="primary" variant="contained">
            Test
          </Button>
          <pre>{JSON.stringify(config, null, 2)}</pre>
        </div>
      </div>
    </ThemeWrapper>
  )
}

export default DebugView
