import React from 'react'
import ConfigProvider from './store/StateProvider'
import { Routes, Route, Navigate } from 'react-router-dom'
import MainView from './views/MainView'
import DebugView from './views/DebugView'
import ErrorPage from './pages/ErrorPage'
import EventsConfigPage from './pages/EventsConfigPage'
import GroupsConfigPage from './pages/GroupsConfigPage'
import LoadingPage from './pages/LoadingPage'
import { useConfig } from './store/StateProvider'
import StatPage from './pages/StatPage'

function View() {
  const config = useConfig()
  return (
    <ConfigProvider>
      <Routes>
        <Route
          index
          element={<Navigate replace to="/29091901/stat" />}
          errorElement={<ErrorPage />}
        />
        <Route path="/:id" element={<MainView />} errorElement={<ErrorPage />} />
        <Route path="/:id/managers" element={<GroupsConfigPage />} errorElement={<LoadingPage />} />
        <Route path="/:id/events" element={<EventsConfigPage />} errorElement={<LoadingPage />} />
        <Route path="/:id/stat" element={<StatPage />} errorElement={<LoadingPage />} />
        <Route path="/:id/db" element={<DebugView />} errorElement={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ConfigProvider>
  )
}

export default View
