/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, createContext, useContext, useEffect, useCallback } from 'react'
import { updateRemote } from './remoteConfigs'
import moment from 'moment-timezone'
import api from '../../../api/oldApiRequests'

const locale = {
  ru: {
    name: 'Язык',
    panelName: 'Панель активности',
    theme: 'Тема',
    workTime: 'Рабочее время',
    settings: 'Настройки',
    viewSettings: 'Настройки отображения',
    eventsSetting: 'Выбор событий',
    timezone: 'Часовой пояс',
    yes: 'Да',
    no: 'Нет',
    customEvents: 'Кастомные события',
    groupSetting: 'Менеджеры',
    dark: 'Темная',
    light: 'Светлая',
    hideManager: 'Скрыть',
    lineType: 'Тип таймлайна',
    noActivity: 'Нет активности',
    hourPrefix: 'ч',
    lunch: 'Обед',
    meeting: 'Встреча',
    calling: 'Звонки',
    lineTypeStandard: 'Стандарт',
    lineTypeWorkButton: 'Кнопка работа',
    lineTypeFullDay: 'За сутки',
  },
  en: {
    name: 'Language',
    panelName: 'Activity bar',
    theme: 'Theme',
    workTime: 'Working time',
    settings: 'Settings',
    viewSettings: 'View setting',
    eventsSetting: 'Events settings',
    groupSetting: 'Managers',
    timezone: 'Timezone',
    yes: 'Yes',
    no: 'No',
    customEvents: 'Custom events',
    dark: 'Dark',
    light: 'Light',
    hideManager: 'Hide',
    lineType: 'Timeline type',
    noActivity: 'No activity',
    hourPrefix: 'h',
  },
}

const localStore = {
  storageName: 'panel',
  saveLocalState(state) {
    const parsedKeys = [
      'theme',
      'lineHeight',
      'lang',
      'groupsLocal',
      'managersLocal',
      'disabledTypes',
      'eventTypesLocal',
    ].reduce((acc, em) => {
      if (em in state) acc[em] = state[em]
      return acc
    }, {})
    localStorage.setItem(this.storageName, JSON.stringify(parsedKeys))
  },
  loadLocalState(state) {
    if (state) {
      const parsedStore = localStorage.getItem(this.storageName)
      if (parsedStore) {
        return { ...state, ...JSON.parse(parsedStore) }
      }
    }
    return state || {}
  },
}

const updater = {
  intervalId: null,
  delay: 60_000,
  ready: true,
  start(updateFunction, delay = 60_000) {
    this.delay = delay
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        if (this.ready) {
          this.ready = false
          updateFunction().finally(() => (this.ready = true))
        }
      }, this.delay)
    }
  },
  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
      this.ready = true
    }
  },
}

const onDayChange = (momentIn, timezone) => {
  if (!momentIn) momentIn = moment()
  if (moment.tz.zone(timezone)) momentIn = momentIn.tz(timezone)
  return { fromTimeUnix: momentIn.startOf('day').unix(), toTimeUnix: momentIn.endOf('day').unix() }
}

const setLocalManagerConfigProperty = (state, propertyKey, user, flag) => {
  const key = `${user.id}-${user.groupId}`
  if (key in state.managersLocal) {
    state.managersLocal[key] = { ...state.managersLocal[key], [propertyKey]: flag }
  } else {
    state.managersLocal = { ...state.managersLocal, [key]: { [propertyKey]: flag } }
  }
}

const Context = createContext()
export const useConfig = () => useContext(Context)

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateCredentials':
      return { ...state, customerId: action.payload.id }
    case 'message':
      return { ...state, message: action.payload }
    case 'setRemoteConfig':
      return { ...state, ...action.payload, configReady: true }
    case 'updateData':
      return { ...state, updating: true }
    case 'rawUpdate':
      return { ...state, ...action.payload }
    case 'setTimelineType':
      setLocalManagerConfigProperty(state, 'timelineType', action.payload.user, action.payload.flag)
      return { ...state }
    case 'setHideManager':
      setLocalManagerConfigProperty(state, 'hideManager', action.payload.user, action.payload.flag)
      return { ...state }
    case 'setDate':
      return { ...state, ...onDayChange(action?.payload, state.remoteConfig?.timezone) }
    default:
      return state
  }
}

const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    localStore.loadLocalState({
      theme: 'dark',
      lang: 'ru',
      lineHeight: '1rem',
      drawer: false,
      configReady: false,
      customerId: null,
      password: '12345',
      updating: false,
      remoteConfig: null,
      eventTypes: null,
      disabledTypes: [],
      managersLocal: {},
      eventTypesLocal: {},
      groups: null,
      groupsLocal: {},
      data: null,
      fromTimeUnix: null,
      toTimeUnix: null,
      lastUpdateTime: null,
    })
  )

  const loc = useCallback(
    (key) => {
      if (state.lang in locale) {
        if (key in locale[state.lang]) {
          return locale[state.lang][key]
        }
      }
      return key || 'No locale string'
    },
    [state.lang]
  )

  useEffect(() => {
    localStore.saveLocalState(state)
  }, [state])

  useEffect(() => {
    if (state.customerId && state.password) {
      updateRemote(state.customerId, state.password, dispatch)
    }
  }, [state.customerId, state.password])

  useEffect(() => {
    if (state.configReady && !state.fromTimeUnix) {
      dispatch({ type: 'setDate' })
    }
  }, [state.configReady, state.fromTimeUnix])

  useEffect(() => {
    if (state.fromTimeUnix && state.toTimeUnix && state.eventTypes) {
      const updateFunction = async () => {
        dispatch({ type: 'rawUpdate', payload: { updating: true } })
        const eventsTypes = state.eventTypes
          .map((event) => event.typeId)
          .filter((type) => !state.disabledTypes.find((t) => t === type))
        const data = await api.getData(
          state.customerId,
          state.password,
          state.fromTimeUnix,
          state.toTimeUnix,
          eventsTypes
        )
        if (data) {
          dispatch({
            type: 'rawUpdate',
            payload: { data, updating: false, lastUpdateTime: Math.round(Date.now() / 1000) },
          })
        }
      }
      updater.stop()
      updateFunction().finally(() => updater.start(updateFunction))
    }
  }, [state.fromTimeUnix, state.toTimeUnix, state.eventTypes, state.disabledTypes])

  useEffect(() => {
    return () => updater.stop()
  }, [])

  return <Context.Provider value={[state, dispatch, loc]}>{children}</Context.Provider>
}

export default ConfigProvider
