import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useConfig } from '../../store/StateProvider'
import ThemeWrapper from '../../components/ThemeWrapper'
import useCalcData from '../../tools/useCalcData'
import ManagerList from './ManagerList'
import AppToolBar from '../../components/AppToolBar'

export default function ErrorPage() {
  const [config, configDispatch, loc] = useConfig()

  const { id } = useParams()
  useEffect(() => {
    if (id) {
      configDispatch({ type: 'updateCredentials', payload: { id } })
    }
  }, [configDispatch, id])

  const parsedData = useCalcData(config)
  const loading = !Boolean(parsedData && parsedData.length)

  return (
    <ThemeWrapper themeName={config.theme}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppToolBar />
      <ManagerList data={parsedData} />
    </ThemeWrapper>
  )
}
